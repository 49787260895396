.toast {
  @include text(16px, $white, bold);
  padding: 12px 32px;
  background: $white;
  z-index: 60;
  max-width: calc(100% - 48px);
  text-align: center;
  box-shadow: none;

  @media(min-width: $sm) {
    width: fit-content;
    width: -moz-fit-content;
  }

  &--info {
    background: $blue-500;
  }

  &--success {
    background: $primary;
  }

  &--error {
    background: $red-500;
  }

  &--warning {
    background-color: $yellow-500;
  }
}
