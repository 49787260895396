@import "resources/assets/sass/variables";
@import "resources/assets/sass/mixins";

.text-input {
  position: relative;

  &--icon {
    .text-input {
      &__native {
        padding: 0 16px 0 42px;
      }

      &__placeholder {
        left: 38px;
      }
    }
  }

  &--password,
  &--currency {
    .text-input {
      &__native {
        padding-right: 48px;
      }
    }
  }

  &__inner {
    display: flex;
    align-items: center;
    position: relative;
    height: 56px;
    background-color: $grey-50;
  }

  &__icon {
    width: 24px;
    height: 24px;
    position: absolute;
    left: 8px;
  }

  &__native {
    width: 100%;
    height: 100%;
    border: 1px solid $grey-100;
    border-radius: 8px;
    padding: 0 16px;
    outline: none;
    background: none;
    transition: border-color 350ms, box-shadow 350ms;
    -webkit-appearance: none;
    -moz-appearance: textfield;
    @include text(16px);

    &:focus {
      @include focus-outline;
    }

    &:not(:placeholder-shown) ~ .text-input__placeholder,
    &:focus ~ .text-input__placeholder {
      top: 0;
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
      color: $primary;
    }

    &:disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }

  &__placeholder {
    position: absolute;
    top: 50%;
    left: 12px;
    padding: 0 4px;
    transform: translateY(-50%);
    font-weight: normal;
    font-size: 16px;
    line-height: 32px;
    color: $grey-500;
    transition: top 350ms, color 350ms, font-size 350ms;
    pointer-events: none;

    &::after {
      content: "";
      display: block;
      width: calc(100% + 8px);
      height: 2px;
      transform: translate(-50%, -50%);
      position: absolute;
      left: 50%;
      top: calc(50% + 1px);
      background-color: $grey-50;
      z-index: -1;
    }
  }

  &__clear {
    @include flex-center;
    width: 24px;
    height: 24px;
    background: none;
    outline: none;
    cursor: pointer;
    border: none;
    position: absolute;
    right: 12px;
  }

  &__password-toggle {
    @include flex-center;
    width: 24px;
    height: 24px;
    background: none;
    outline: none;
    cursor: pointer;
    border: none;
    position: absolute;
    right: 12px;

    &__hide {
      display: none;
    }

    &--active {
      .text-input__password-toggle {
        &__show {
          display: none;
        }

        &__hide {
          display: block;
        }
      }
    }

    svg {
      path {
        fill: $primary;
      }
    }
  }

  &__currency {
    position: absolute;
    top: 26px;
    right: 20px;
    transform: translateY(-50%);
    @include text(16px, $grey-900, bold);
  }

  &__error {
    margin: 4px 0 0;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: $red-500;
  }
}
