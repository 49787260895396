.file-input {
  overflow: hidden;
  position: relative;
  width: 100%;

  &--has-error {
    .file-input {
      &__inner {
        border-color: $red-500;
      }

      &__error {
        display: block;
      }
    }
  }

  &__label {
    display: flex;
    flex-direction: column;
    margin-bottom: 6px;
    @include text(14px, $grey-500, bold);

    @media(min-width: $sm) {
      flex-direction: row;
      align-items: center;
      margin-bottom: 2px;
    }

    &__desc {
      @include label($grey-500);

      @media(min-width: $sm) {
        margin-left: 12px;
      }
    }
  }

  &__inner {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 16px 0;
    border: solid 1px $grey-100;
    border-radius: 8px;
    background-color: $grey-50;

    @media(min-width: $sm) {
      flex-wrap: nowrap;
    }
  }

  &__native-input {
    tab-index: -1;
    position: absolute;
    opacity: 0;
    left: 0;
    bottom: 0;
    z-index: -9999;
    pointer-events: none;
  }

  &__preview {
    width: 64px;
    height: 64px;
    object-fit: cover;
    object-position: center;
    flex-shrink: 0;
    margin-left: 16px;
    border-radius: 4px;

    &[src=''] {
      display: none;
    }

    &--rounded {
      border-radius: 50%;
    }
  }

  &__button {
    flex-shrink: 0;
  }

  &__action {
    display: flex;
    flex-direction: column;
    padding: 12px;
    overflow: hidden;

    @media(min-width: $sm) {
      align-items: center;
      flex-direction: row;
    }
  }

  &__filename {
    font-weight: normal;
    font-size: 14px;
    line-height: 26px;
    color: $grey-500;
    overflow: hidden;
    text-overflow: ellipsis;

    @media(min-width: $sm) {
      font-size: 16px;
      margin-left: 16px;
    }
  }

  &__error {
    display: none;
    margin: 4px 0 0;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: $red-500;
  }
}
