.modal {
  display: none;

  &--active {
    display: block;
  }

  &[aria-hidden="false"] {
    animation: modalFadeIn 350ms forwards;
  }
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

