.textarea {
  &__inner {
    display: flex;
    align-items: center;
    position: relative;
    background-color: $grey-50;
  }

  &__native {
    @include text(16px, $grey-900);
    width: 100%;
    height: 100%;
    min-height: 56px;
    border: 1px solid $grey-100;
    border-radius: 8px;
    padding: 12px 16px;
    outline: none;
    background: none;
    transition: border-color 350ms, box-shadow 350ms;
    font-family: $default-font;
    resize: vertical;

    &:focus {
      @include focus-outline;
    }

    &:not(:placeholder-shown) ~ .textarea__placeholder,
    &:focus ~ .textarea__placeholder {
      top: -8px;
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
      color: $primary;
    }

    &:disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }

  &__placeholder {
    @include text(16px, $grey-500);
    position: absolute;
    top: 12px;
    left: 12px;
    padding: 0 4px;
    transition: top 350ms, color 350ms, font-size 350ms;
    pointer-events: none;
    z-index: 0;

    &::after {
      content: "";
      display: block;
      width: calc(100% + 8px);
      height: 2px;
      transform: translate(-50%, -50%);
      position: absolute;
      left: 50%;
      top: calc(50% + 1px);
      background-color: $grey-50;
      z-index: -1;
    }
  }

  &__chars-left {
    margin-top: 4px;
    @include label();

    &__value {

    }
  }

  &__error {
    margin: 4px 0 0;
    @include label($red-500, true);
  }
}
