.pwa-message {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: fixed;
  bottom: 40px;
  left: 50%;
  transform: translate(-50%, 0);
  width: fit-content;
  width: -moz-fit-content;
  max-width: calc(100vw - 24px * 2);
  padding: 12px 14px;
  background-color: $white;
  border-radius: 8px;
  z-index: 30;
  box-shadow: 0 6px 12px rgba($black, 0.6);
  transition: transform 350ms;

  &--hidden {
    transform: translate(-50%, calc(100% + 40px));
  }

  @media(min-width: $sm) {
    padding: 12px 24px;
  }

  &__icon {
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    border-radius: 4px;
    margin-right: 12px;
  }

  &__text {
    margin-right: 24px;
    @include text(12px);

    @media(min-width: $sm) {
      @include text(14px);
    }

    b {
      font-weight: bold;
    }
  }

  &__close {
    position: absolute;
    top: 6px;
    right: 6px;
    background-color: rgba($grey-100, 0.8);
    cursor: pointer;
    width: 24px;
    height: 24px;
    border-radius: 8px;
    border: none;
    outline: none;

    svg {
      width: 18px;
      height: 18px;
    }
  }
}
