.tooltip {
  &__trigger {
  }

  &__content {
    background-color: $grey-900;
    padding: 6px 12px;
    border-radius: 4px;
    visibility: hidden;
    pointer-events: none;
    position: absolute;
    z-index: 3;
    @include text(12px, $white);

    &[data-popper-placement] {
      visibility: visible;
      pointer-events: all;
    }

    &::after {
      content: "";
      display: block;
      width: 6px;
      height: 6px;
      background-color: $grey-900;
      transform: rotate(45deg) translate(-50%, -50%);
      position: absolute;
    }

    &[data-popper-placement^='top']::after {
      bottom: 2px;
      left: 50%;
    }

    &[data-popper-placement^='bottom']::after {
      top: 2px;
      left: 50%;
    }

    &[data-popper-placement^='left']::after {
      top: 50%;
      right: 2px;
    }

    &[data-popper-placement^='right']::after {
      top: 50%;
      left: 2px;
    }
  }
}
