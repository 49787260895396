.dropdown {
  display: flex;
  flex-direction: column;
  min-height: 56px;
  position: relative;
  border: 1px solid $grey-100;
  border-radius: 8px;
  background-color: $grey-50;

  &--has-value {
    border-color: $primary;

    .dropdown {
      &__placeholder {
        top: 0;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        color: $primary;
      }
    }
  }

  .choices {
    flex-grow: 1;
    margin: 0;

    &[data-type="select-multiple"] {
      .choices__inner {
        padding-right: 32px;
      }
    }

    &.is-open ~ .dropdown {
      &__placeholder {
        top: 0;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        color: $primary;
      }

      &__arrow {
        transform: translateY(-50%) rotate(180deg);
      }
    }

    &__inner {
      height: 100%;
      padding: 16px 8px;
      border: none;
      background: none;
    }

    &__input {
      background: none;
      width: 100%;
      display: block !important;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      z-index: -9999;
      opacity: 0;
    }

    &__list {
      &--dropdown {
        margin: 0;
        border: solid 1px $grey-100;
        border-radius: 0 0 4px 4px;
      }

      &--multiple {
        .choices {
          &__item {
            border-radius: 4px;
            border: none;
            background-color: $primary;

            .choices__button {
              border-left: solid 1px rgba($grey-50, .5);
            }
          }
        }
      }
    }

    &__placeholder {
      display: none;
    }

    &::after {
      display: none;
    }
  }

  &__select {
    display: none;
  }

  &__placeholder {
    position: absolute;
    top: 28px;
    left: 12px;
    padding: 0 4px;
    transform: translateY(-50%);
    font-weight: normal;
    font-size: 16px;
    line-height: 32px;
    color: $grey-500;
    transition: top 350ms, color 350ms, font-size 350ms;
    pointer-events: none;
    z-index: 0;

    &::after {
      content: "";
      display: block;
      width: calc(100% + 8px);
      height: 2px;
      transform: translate(-50%, -50%);
      position: absolute;
      left: 50%;
      top: 50%;
      background-color: $grey-50;
      z-index: -1;
    }
  }

  &__arrow {
    position: absolute;
    transform: translateY(-50%);
    top: 28px;
    right: 8px;
    pointer-events: none;
    transition: transform 350ms;
  }
}
