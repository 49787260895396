.side-menu {
  $root: &;
  background: $white;
  border-radius: 8px;
  border: solid 1px $grey-100;
  overflow: hidden;

  &--expanded {
    #{$root} {
      &__topbar {
        &__toggle {
          transform: rotate(180deg);
        }
      }

      &__items {
        display: block;
      }
    }
  }

  &__topbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 36px 24px 36px;

    @media(min-width: $sm) {
      padding: 36px 36px 36px 54px;
    }

    &__main {
    }

    &__title {
      margin-bottom: 12px;
      font-weight: bold;
      font-size: 30px;
      line-height: 36px;
      color: $grey-900;
    }

    &__desc {
      font-size: 16px;
      line-height: 26px;
      color: $grey-500;
    }

    &__toggle {
      @include flex-center;
      background: none;
      border: none;
      cursor: pointer;
      width: 40px;
      height: 40px;

      @media(min-width: $md) {
        display: none;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__items {
  }

  &__item {
    $item: &;
    position: relative;
    border-top: solid 1px $grey-100;
    border-bottom: solid 1px $grey-100;
    margin: -1px 0;

    &--has-submenu.active {
      & > a {
        & > svg {
          transform: rotate(-90deg);
        }
      }
    }

    &--has-submenu.current {
      & > a {
        background-color: $green-100;

        &::after {
          content: "";
          display: block;
          height: 100%;
          width: 4px;
          background-color: $primary;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }

    button,
    a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 12px 24px;
      cursor: pointer;
      background: none;
      border: none;
      outline: none;
      transition: background-color 350ms;
      position: relative;
      @include text(14px);

      svg {
        transition: transform 350ms;
      }

      &:hover {
        background-color: rgba($primary, .2);
        text-decoration: none;
      }

      &.current {
        color: $primary;
        font-weight: bold;

        &::after {
          content: "";
          display: block;
          height: 100%;
          width: 4px;
          background-color: $primary;
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      &.expanded {
        img {
          transform: rotate(180deg);
        }
      }
    }
  }

  &__submenu {
    padding-left: 12px;
    //max-height: 0;
    //overflow: hidden;

    @media(min-width: $sm) {
      padding-left: 24px;
    }

    a.current {
      &::after {
        display: none;
      }
    }
  }
}
