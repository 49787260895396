.new-password-form {
  max-width: 848px;
  margin: 0 auto;
  padding: 60px 24px;
  background-color: $white;
  border-radius: 4px;

  @media(min-width: $sm) {
    padding: 60px;
  }

  &--dashboard {
    max-width: none;
    padding: 0;
  }

  &__title {
    @include heading-3();
  }

  &__inputs {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    max-width: 460px;
    margin: 36px 0 30px;

    &__item {
      &__desc {
        margin-top: 4px;
        @include label($grey-500);
      }
    }
  }
}
