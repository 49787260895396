.phone-input {
  height: 56px;

  .iti {
    display: flex;
    background-color: $grey-50;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 8px;

    &__flag-container {
      padding: 0;
      background: none;
    }

    &__selected-flag {
      background: none;
      outline: none;
    }

    &__flag {
      background-image: url("/img/flags.png");
      box-shadow: none;
      border-radius: 2px;
      border: 0.5px solid $grey-100;
      padding: 0;
    }

    &__arrow {
      border-top-color: $grey-200;
    }

    &__selected-dial-code {
      @include text(14px, $blue-500, bold);
    }
  }

  &__input {
    width: 100%;
    height: 100%;
    border: 1px solid $grey-100;
    background: none;
    border-radius: 8px;
    font-family: $default-font;
    appearance: none;
    @include text(16px);

    &:focus {
      @include focus-outline;
    }

    &::placeholder {
      color: $grey-500;
    }
  }

  &__error {
    margin: 4px 0 0;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: $red-500;
  }
}
