.basic-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;

  &__overlay {
    position: fixed;
    z-index: 51;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba($black, .5);
  }

  &__wrapper {
    @include flex-center;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 52;

    @media(min-width: $sm) {
      padding: 56px 24px;
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    max-width: calc(100vw - 32px);
    max-height: calc(100vh - 120px);
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 20px 40px rgba($black, 0.2);
    background-color: $white;
    position: relative;

    &--expand {
      width: 100%;
    }

    @media(min-width: $sm) {
      flex-direction: row;
    }

    @media(min-width: $lg) {
      border-radius: 24px;
      max-width: 760px;
    }
  }


  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 42px 32px;
    overflow: auto;

    &--flat {
      padding: 0;
    }

    &:not(.basic-modal__content--flat) {
      @media(min-width: $sm) {
        padding: 42px 64px;
      }

      @media(min-width: $lg) {
        padding: 80px 94px 58px;
      }
    }
  }

  &__close {
    @include flex-center;
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    z-index: 1;
    border-radius: 8px;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    margin-left: auto;

    @media(min-width: $md) {
      position: absolute;
      top: 12px;
      right: 12px;
    }

    svg {
      display: block;
      pointer-events: none;
    }
  }

  &__title {
    margin-bottom: 12px;
    @include heading-3();
  }

  &__desc {
    @include text(14px);
  }

  &__fields {
    display: grid;
    grid-gap: 12px;
    margin: 24px 0;

    &__desc {
      @include label();

      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }

  &__actions {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 12px;
    margin-top: 42px;
  }
}
