.navbar {
  display: flex;
  align-items: center;
  position: fixed;
  height: $navbar-height;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 30;
  background-color: $transparent;
  transition: background-color 350ms;

  &--dashboard,
  &--mobile-menu-open,
  &--light,
  &--scroll {
    background-color: $white;

    .navbar {
      &__brand {
        color: $primary;
      }

      &__nav {
        &__item {
          color: $grey-900;
        }

        &__actions {
          &__item {
            &.btn--outline {
              color: $primary;
            }

            &.btn--white {
              @extend .btn--primary;
            }
          }

          &__lang {
            &__button {
              color: $grey-900;

              svg {
                path {
                  fill: $grey-900;
                }
              }
            }

            &__item {
              color: $grey-900;
            }
          }
        }

        &__account {
          &__name {
            color: $grey-500;
          }
        }
      }

      &__hamburger {
        color: $grey-900;
      }
    }
  }

  &--mobile-menu-open {
    .navbar {
      &__nav {
        &__actions {
          &__lang {
            display: block;
          }
        }
      }
    }
  }

  &__content {
    flex-grow: 1;
    padding: 0 28px;
    display: flex;
    align-items: center;
  }

  &__brand {
    flex-shrink: 0;
    margin-right: 48px;
    color: $white;
    transition: color 350ms;

    svg {
      max-width: 100%;

      path {
        fill: currentColor;
      }
    }
  }

  &__nav {
    flex-grow: 1;
    min-width: 0;

    &__items {
      display: flex;
      align-items: center;
      margin-left: -12px;
    }

    &__item {
      text-decoration: none;
      padding: 12px;
      transition: color 350ms;
      white-space: nowrap;
      @include text(16px, $white);
      @include display-from($lg);
    }

    &__actions {
      margin-left: auto;
      min-width: 0;

      @media(min-width: $lg) {
        padding-left: 32px;
      }

      &__items {
        display: flex;
        align-items: center;
        white-space: nowrap;
      }

      &__item {
        margin-left: 16px;
        @include display-from($lg, flex);

        &.btn--outline {
          color: $white;
        }
      }

      &__lang {
        margin-right: 12px;
        position: relative;
        @include display-from($lg);

        @media(min-width: $lg) {
          margin-right: 24px;
        }

        &--active {
          .navbar__nav__actions__lang {
            &__button {
              svg {
                transform: rotate(180deg);
              }
            }

            &__items {
              display: block;
            }
          }
        }

        &__button {
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          outline: none;
          border: none;
          background: none;
          @include text(16px, $white, bold);
          text-transform: uppercase;

          svg {
            margin-left: 6px;
            display: block;
          }
        }

        &__items {
          position: absolute;
          padding: 0 4px;
          display: none;
        }

        &__item {
          @include text(16px, $white, bold);
          text-transform: uppercase;

          a {
            color: inherit;

            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }

    &__account {
      display: flex;
      align-items: center;
      margin-left: 32px;
      @include display-from($lg, flex);

      &__avatar {
        flex-shrink: 0;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        object-fit: cover;
      }

      &__name {
        margin-left: 16px;
        white-space: nowrap;
        transition: color 350ms;
        @include crop-text;
        @include text(16px, $white, bold);
      }
    }
  }

  &__hamburger {
    margin-left: auto;
    color: $white;

    @media(min-width: $lg) {
      display: none;
    }
  }
}
