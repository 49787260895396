.like-button {
  transition: background-color 250ms;

  &__icon-wrapper {
    @include flex-center;
    position: relative;
    pointer-events: none;
  }

  &__icon {
    position: absolute;
    width: 100%;
    height: 100%;

    &--empty,
    &--filled,
    &--active {
      transition: opacity 350ms;
    }
  }

  &--primary {
    .like-button {
      &__icon-wrapper {
        width: 24px;
        height: 24px;
      }
    }
  }

  &--campaign {
    @include flex-center;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    background-color: rgba($white, 0.2);
    border: none;
    outline: none;
    cursor: pointer;

    .like-button {
      &__icon-wrapper {
        width: 22px;
        height: 22px;
      }

      &__icon {
        path {
          fill: $red-500;
        }
      }
    }
  }

  &:not(.like-button--active) {
    &:hover {
      .like-button {
        &__icon {
          &--empty {
            opacity: 0;
          }

          &--filled {
            opacity: 1;
          }
        }
      }
    }

    .like-button {
      &__icon {
        &--empty {
          opacity: 1;
        }

        &--filled {
          opacity: 0;
        }

        &--active {
          opacity: 0;
        }
      }
    }
  }

  &--active {
    &.like-button--primary {
      background-color: $red-100;

      &:hover {
        box-shadow: 0 6px 12px rgba($red-100, 0.6) !important;
      }
    }

    &.like-button--campaign {
      background-color: $red-100;
    }

    .like-button {
      &__icon {
        &--empty {
          opacity: 0;
        }

        &--filled {
          opacity: 0;
        }

        &--active {
          opacity: 1;
        }
      }
    }
  }
}
