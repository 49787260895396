@import "resources/assets/sass/variables";
@import "resources/assets/sass/mixins";

.checkbox {
  position: relative;
  display: flex;
  align-items: center;

  &--rounded {
    .checkbox__check {
      border-radius: 50%;
    }
  }

  &__native {
    position: absolute;
    opacity: 0;
    pointer-events: none;
    z-index: -1;

    &:not(:disabled) {
      &:hover ~ .checkbox__check {
        border-color: $primary;
        color: $grey-200;
      }

      &:focus ~ .checkbox__check {
        border-color: $grey-200;
        @include focus-outline;
        color: $grey-200;
      }

      &:checked:hover ~ .checkbox__check {
        background-color: $primary-dark;
      }
    }

    &:checked ~ .checkbox__check {
      background-color: $primary;
      border-color: rgba(0, 0, 0, 0.05);
      color: $white;
    }

    &:disabled ~ .checkbox__check,
    &:disabled ~ .checkbox__label {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }

  &__check {
    width: 20px;
    height: 20px;
    @include flex-center;
    border: 1px solid $grey-200;
    background-color: $white;
    border-radius: 4px;
    color: $white;
    transition: box-shadow 350ms, background-color 350ms, border-color 350ms, color 350ms;
    cursor: pointer;

    svg {
      color: inherit;

      path {
        fill: currentColor;
      }
    }
  }

  &__label {
    margin-left: 8px;
    color: $grey-500;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    cursor: pointer;
  }
}
