.payment-status {
  .basic-modal {
    &__main {
      @media(min-width: $lg) {
        min-height: 540px;
      }

      @media(min-width: $md) {
        max-width: 640px;
      }
    }

    &__close {
      position: absolute;
      top: 16px;
      right: 16px;
      background-color: rgba($grey-500, .2);
      border-radius: 4px;

      svg {
        path {
          fill: $white;
        }
      }
    }
  }

  &__header {
    min-height: 132px;
    position: relative;
    background-image: url("/img/modal-header-bg.svg");
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 24px;
  }

  &__title {
    margin-bottom: 16px;
    text-align: center;
    @include heading-2();

    &--success {
      color: $primary;
    }

    &--failed {
      color: $red-500;
    }
  }

  &__desc {
    max-width: 360px;
    text-align: center;
    margin-bottom: 36px;
    @include text(18px, $grey-500);
  }

  &__loader {
    position: relative;
    margin-top: -20px;
    margin-bottom: 32px;

    &__count {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      @include text(16px, $primary);
    }

    .dual-rings-loader {
      transform: scale(.75);
    }
  }

  &__timeout-message {
    max-width: 480px;
    margin-bottom: 36px;
    text-align: center;
    @include text(18px, $grey-500);
  }

  &__share {
    margin-bottom: 32px;
  }

  &__close {
    margin-top: auto;
  }
}
