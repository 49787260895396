.progress-bar {
  position: relative;
  height: 16px;
  border: solid 2px $grey-100;
  background-color: $grey-100;
  border-radius: 2px;
  overflow: hidden;

  span {
    display: block;
    background: linear-gradient(90deg, lighten($primary, 45) 0%, $primary 100%);
    height: 100%;
    border-radius: 2px;
  }
}
