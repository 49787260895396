.pagination {
  display: flex;

  &__prev {
    margin-right: 12px;
  }

  &__next {
    margin-left: 12px;
  }

  &__item {
    width: 26px;
    height: 26px;
    border-radius: 4px;
    transition: background-color 350ms;
    @include flex-center;
    @include text(14px, $grey-500);

    &:not(.pagination__item--disabled) {
      &:hover {
        text-decoration: none;
        background-color: $green-100;
      }
    }

    &--active {
      color: $primary;
      background-color: $green-100;
      font-weight: bold;
    }
  }
}
