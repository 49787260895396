.campaign-card {
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  overflow: hidden;
  background-color: $white;

  &:hover {
    text-decoration: none;

    .campaign-card {
      &__thumbnail {
        &__bg {
          transform: scale(1.25);
        }
      }
    }
  }

  &__thumbnail {
    padding-top: 75%;
    position: relative;
    border-radius: 0 0 2px 2px;
    overflow: hidden;
    z-index: 0;

    &__bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -1;
      transition: transform 900ms;
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 20px 12px 6px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &__badges {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-top: auto;
    }

    &__badge {
      @include flex-center;
      padding: 4px 8px;
      border-radius: 24px;
      margin-right: 8px;
      margin-bottom: 8px;
      word-break: break-word;

      &--featured {
        background-color: $yellow-100;
        @include label($yellow-500, true);
      }

      &--category {
        background-color: $blue-100;
        @include label($blue-500, true);
      }
    }
  }

  &__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 20px 12px 24px;

    &__region {
      display: flex;
      align-items: center;
      margin-bottom: 4px;

      &__icon {
        margin-right: 6px;
      }

      &__name {
        @include text(14px, $grey-500);
      }
    }

    &__title {
      @include heading-4();
      @include crop-text;
      margin-bottom: 8px;
    }

    &__stats {
      display: flex;
      margin-top: auto;
      margin-bottom: 8px;

      &__current {
        margin-right: 10px;
        @include heading-4($primary);
      }

      &__goal {
        @include heading-4($grey-500, true);
      }
    }

    &__progress-bar {
      margin-bottom: 4px;
    }

    &__summary {
      display: flex;

      &__value {
        margin-right: 4px;
        @include text(14px, $primary, bold);
      }

      &__label {
        @include text(14px, $grey-200, bold);

        &--active {
          color: $primary;
        }
      }
    }

    &__collected {
      &__value {
        @include text(16px, $primary, bold);
      }

      &__label {
        @include text(16px, $grey-200, bold);
      }
    }

    &__term {
      display: flex;
      align-items: center;

      &__label {
        @include text(14px, $grey-200, bold);
      }

      &__icon {
        width: 16px;
        height: 16px;
        margin-left: 4px;
      }
    }
  }
}
