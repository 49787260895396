.cookies-info {
  position: fixed;
  z-index: 40;
  bottom: 0;
  left: 0;
  width: 100%;
  max-height: 100vh;
  overflow: auto;
  background-color: $white;
  box-shadow: 0 0 12px rgba($black, .2);
  transform: translateY(100%);
  animation: slideInCookiesInfo 500ms forwards 1s;

  &--hidden {
    animation: slideOutCookiesInfo 500ms forwards;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 32px 0;

    @media(min-width: $sm) {
      flex-direction: row;
    }
  }

  &__text {
    max-width: 1200px;
    @include text(16px);

    a {
      color: $blue-500;
      text-decoration: underline;
    }
  }

  &__accept {
    margin-top: 32px;

    @media(min-width: $sm) {
      margin-top: 0;
      margin-left: 32px;
    }
  }
}

@keyframes slideInCookiesInfo {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideOutCookiesInfo {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}
