.verify-account-modal {
  .basic-modal {
    &__main {
      @media(min-width: $lg) {
        min-height: 540px;
      }

      @media(min-width: $md) {
        max-width: 640px;
      }
    }

    &__close {
      position: absolute;
      top: 16px;
      right: 16px;
      background-color: rgba($grey-500, .2);
      border-radius: 4px;

      svg {
        path {
          fill: $white;
        }
      }
    }
  }

  &__header {
    min-height: 132px;
    position: relative;
    background-image: url("/img/modal-header-bg.svg");
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__content {
    padding: 40px 24px;
    margin: 0 auto;
    max-width: 440px;
  }

  &__title {
    margin-bottom: 12px;
    @include heading-2();
  }

  &__desc {
    max-width: 360px;
    margin-bottom: 42px;
    @include text(18px, $grey-500);
  }

  &__action {
    margin-bottom: 52px;
  }

  &__close {
    margin: auto auto 0;
  }
}
