@import "~reset-css";
@import "~toastify-js/src/toastify.css";
@import "~swiper/swiper-bundle.min.css";
@import "~choices.js/src/styles/choices";
@import "~intl-tel-input/build/css/intlTelInput.min.css";

@import "variables";
@import "mixins";
@import "typography";
@import "layout";
@import "utils";

@import "components/shared/new-password-form";
@import "components/shared/button";
@import "components/shared/hamburger";
@import "components/shared/navbar";
@import "components/shared/mobile-menu";
@import "components/shared/cookies-info";
@import "components/shared/toast";
@import "components/shared/campaign-card";
@import "components/shared/progress-bar";
@import "components/shared/modal";
@import "components/shared/side-menu";
@import "components/shared/like-button";
@import "components/shared/pagination";
@import "components/shared/basic-modal";
@import "components/shared/popover";
@import "components/shared/tooltip";
@import "components/shared/dual-rings-loader";
@import "components/shared/verify-account-modal";
@import "components/shared/payment-status";
@import "components/shared/pwa-message";

@import "components/shared/form/checkbox";
@import "components/shared/form/radio";
@import "components/shared/form/text-input";
@import "components/shared/form/file-input";
@import "components/shared/form/phone-input";
@import "components/shared/form/textarea";
@import "components/shared/form/dropdown";
@import "components/shared/form/form-control";

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-family: $default-font;
  background-color: #F4F7F7;
}

a {
  color: $blue-500;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

button {
  padding: 0;
}

textarea,
input[type='text'] {
  appearance: none;
}

[data-collapse-content] {
  max-height: 0;
  overflow: hidden;

  &.active {
    max-height: none;
    overflow: visible;
  }
}

:invalid {
  box-shadow: none;
}

:-moz-submit-invalid {
  box-shadow: none;
}

:-moz-ui-invalid {
  box-shadow: none;
}
