.popover {
  &__trigger {
  }

  &__dismiss {
  }

  &__content {
    padding: 10px;
    background-color: $white;
    border-radius: 4px;
    visibility: hidden;
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 3;
    filter: drop-shadow(0px 12px 24px rgba($black, 0.2));
    border: solid 1px $grey-200;
    opacity: 0;
    transition: opacity 250ms;

    &[data-popper-placement] {
      opacity: 1;
      visibility: visible;
      pointer-events: all;
    }
  }

  &__actions {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 8px;

    &--column {
      grid-template-columns: 1fr
    }
  }
}
