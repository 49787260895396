.btn {
  @include flex-center;
  height: 56px;
  padding: 0 24px;
  background: none;
  border: none;
  border-radius: 4px;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  font-family: $default-font;
  text-decoration: none;
  cursor: pointer;
  outline: none;
  transition: box-shadow 350ms, background-color 350ms, color 350ms;

  @media(min-width: $sm) {
    font-size: 16px;
    line-height: 18px;
  }

  &:hover {
    text-decoration: none;
  }

  &--primary,
  &--secondary,
  &--outline {
    &:focus {
      @include focus-outline;
    }
  }

  &--primary {
    background-color: $primary;
    color: $white;

    &:not(:disabled):hover {
      box-shadow: 0 6px 12px rgba($primary, 0.6);
    }

    &:active {
      background-color: $primary-dark;
    }
  }

  &--secondary {
    background-color: rgba($primary, 0.1);
    color: $primary;

    &:not(:disabled):hover {
      background-color: $primary;
      color: $white;
    }

    &:focus {
      background-color: rgba($primary, 0.1);
      color: $primary;
    }

    &:active {
      background-color: $primary-dark;
      color: $white;
    }
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.6;
  }

  &--outline {
    color: $primary;
    border: 1px solid $grey-100;

    &:not(:disabled):hover,
    &:focus {
      background-color: rgba($primary, 0.1);
    }

    &:focus {
      border-color: $transparent;
    }

    &:active {
      border-color: $primary;
    }
  }

  &--empty {
    color: $primary;

    &:not(:disabled):hover,
    &:focus {
      color: $primary-dark;
    }

    &:active {
      background-color: $grey-100;
      color: $primary;
    }
  }

  &--white {
    color: $primary;
    background-color: $white;

    &:not(:disabled):hover {
      box-shadow: 0 6px 12px rgba($white, 0.6);
    }

    &:active {
      background-color: darken($white, 5);
    }
  }

  &--danger {
    background-color: $red-500;
    color: $white;

    &:not(:disabled):hover {
      box-shadow: 0 6px 12px rgba($red-500, 0.6);
    }

    &:active {
      background-color: darken($red-500, 5);
    }
  }

  &--danger-outline {
    color: $red-500;
    border: solid 1px $red-500;

    &:not(:disabled):hover {
      background-color: $red-500;
      color: $white;
    }

    &:active {
      background-color: darken($red-500, 5);
    }
  }

  &--dark-outline {
    color: $grey-900;
    border: solid 1px $grey-100;

    &:not(:disabled):hover {
      background-color: $grey-100;
    }

    &:active {
      background-color: darken($grey-100, 5);
    }
  }

  &--google {
    @extend .btn--outline;
  }

  &--facebook {
    @extend .btn--primary;
    background-color: #3B5998;

    &:not(:disabled):hover {
      box-shadow: 0 6px 12px rgba(#3B5998, 0.6);
    }

    &:active {
      background-color: lighten(#3B5998, 10);
    }
  }

  &--apple {
    @extend .btn--primary;
    background-color: #000;

    &:not(:disabled):hover {
      box-shadow: 0 6px 12px rgba(#000, 0.6);
    }

    &:active {
      background-color: lighten(#000, 10);
    }
  }

  &--xs {
    height: 34px;
    font-size: 14px;
    padding: 0 10px;

    .btn__icon {
      width: 16px;
      height: 16px;
    }

    &.btn--rect {
      width: 34px;
    }
  }

  &--sm {
    height: 40px;
    padding: 0 12px;

    .btn__icon {
      width: 16px;
      height: 16px;
    }

    &.btn--rect {
      width: 40px;
    }
  }

  &--lg {
    height: 72px;
    padding: 0 28px;

    &.btn--rect {
      width: 72px;
    }
  }

  &--rect {
    width: 56px;
    padding: 0;
    @include flex-center;
  }

  &__icon {
    flex-shrink: 0;
    margin-right: 10px;
    width: 24px;
    height: 24px;

    path {
      fill: currentColor;
    }

    &--single {
      margin-right: 0;
    }

    &--danger {
      path {
        fill: $red-500;
      }
    }
  }
}
