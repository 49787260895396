@import "resources/assets/sass/variables";
@import "resources/assets/sass/mixins";

.radio {
  position: relative;
  display: flex;
  align-items: center;

  &--outline {
    padding: 12px 16px;
    border-radius: 8px;
    border: 1px solid $grey-100;
  }

  &__native {
    position: absolute;
    z-index: -1;
    pointer-events: none;
    opacity: 0;

    &:not(:disabled) {
      &:hover ~ .radio__check {
        background-color: $grey-200;
        border-color: $grey-200;
      }

      &:focus ~ .radio__check {
        @include focus-outline;
        background-color: $grey-200;
      }

      &:checked:focus ~ .radio__check {
        background-color: $primary;
        border-color: $primary;
      }

      &:checked:hover ~ .radio__check {
        background-color: $primary-dark;
        border-color: $primary-dark;
      }
    }

    &:checked ~ .radio__check {
      background-color: $primary;
      border-color: $primary;
    }

    &:disabled ~ .radio__check,
    &:disabled ~ .radio__label {
      cursor: not-allowed;
      opacity: 0.4;
    }
  }

  &__check {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: $white;
    border: 1px solid $grey-200;
    width: 20px;
    height: 20px;
    color: $white;
    transition: box-shadow 350ms, background-color 350ms, border-color 350ms, color 350ms;
    cursor: pointer;

    &::after {
      content: "";
      display: block;
      width: 8px;
      height: 8px;
      background-color: currentColor;
      border-radius: 50%;
    }
  }

  &__label {
    margin-left: 8px;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: $grey-500;
    cursor: pointer;
  }
}
