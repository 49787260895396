.mobile-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(100% - #{$navbar-height});
  z-index: 5;
  padding: 32px;
  background-color: $white;
  transform: translateX(-100%);
  visibility: hidden;
  overscroll-behavior: contain;
  overflow: auto;

  @media(min-width: $lg) {
    display: none;
  }

  &--active {
    animation: slideInMobileMenu 300ms forwards;
  }

  &--hidden {
    animation: slideOutMobileMenu 300ms forwards;
  }

  &__nav {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    align-items: center;
    margin-bottom: 8px;
    padding: 32px 0;

    &__item {
      flex-shrink: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      padding: 16px 0;
      font-weight: bold;
      text-decoration: none;
      @include text(16px);
    }
  }

  &__actions {
    flex-shrink: 0;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    max-width: 420px;
    width: 100%;

    &__item {
      flex-shrink: 0;

      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }
}

@keyframes slideInMobileMenu {
  from {
    transform: translateX(-100%);
    visibility: hidden;
  }
  to {
    transform: translateX(0);
    visibility: visible;
  }
}


@keyframes slideOutMobileMenu {
  from {
    transform: translateX(0);
    visibility: visible;
  }
  to {
    transform: translateX(-100%);
    visibility: hidden;
  }
}
