.hamburger {
  padding: 15px 15px;
  display: inline-block;
  text-transform: none;
  background: none;
  border: 0;
  margin: 0;
  outline: none;
  overflow: visible;
  cursor: pointer;
  transition: color 350ms;

  &__box {
    width: 40px;
    height: 24px;
    display: inline-block;
    position: relative;
  }

  &__inner,
  &__inner::before,
  &__inner::after {
    width: 40px;
    height: 4px;
    background-color: currentColor;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 350ms;
    transition-timing-function: ease;
  }

  &__inner {
    display: block;
    top: 50%;
    margin-top: -2px;

    &::before,
    &::after {
      content: "";
      display: block;
    }

    &::before {
      top: -10px;
    }

    &::after {
      bottom: -10px;
    }
  }
}


.hamburger--collapse .hamburger__inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse .hamburger__inner::after {
  top: -20px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
}

.hamburger--collapse .hamburger__inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse.hamburger--active .hamburger__inner {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--collapse.hamburger--active .hamburger__inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
}

.hamburger--collapse.hamburger--active .hamburger__inner::before {
  top: 0;
  transform: rotate(-90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}
