.form-control {
  &--touched {
    .form-control__input {
      &:valid {
        border-color: $primary;
      }

      &:invalid {
        border-color: $red-500;
      }
    }
  }

  &--has-error {
    .form-control__error {
      display: block;
    }

    .form-control__input {
      border-color: $red-500;
    }
  }

  &__error {
    display: none;
  }
}
